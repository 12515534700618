<template>
  <v-container>
    <DTicket />
  </v-container>
</template>
<script>
import DTicket from "../components/cordt/DTicket.vue";

export default {
  metaInfo: {
    title: "Delivery Ticket",
  },
  components: {
    DTicket,
  },
};
</script>
