import ExcelJS from "exceljs/dist/es5/exceljs.browser";
//import ExcelJS from "exceljs";
import { format } from "date-fns";

export default {
  async getData(workbook, textData, count, deliveryDate) {
    let worksheet = null;

    try {
      worksheet = workbook.getWorksheet("DELIVERY TICKET");
      this.clearWorksheet(worksheet);
    } catch {
      return null;
    }

    worksheet.getCell("S2").value = textData[0]; // Order # "A"

    const modelInfo = await this.getPartNumbers(textData[3]); // Product Code "D"
    worksheet.getCell("B16").value = modelInfo.product; // Product Code "D"
    worksheet.getCell("E16").value = modelInfo.product; // Model
    worksheet.getCell("G16").value = modelInfo.description; // Desc

    worksheet.getCell("J16").value = textData[10]; // Serial
    worksheet.getCell("M16").value = textData[11]; // Equipment ID

    worksheet.getCell("S6").value = format(deliveryDate, "P"); // Delivery Date MM/DD/YYYY
    worksheet.getCell("E8").value = textData[12]; // Customer Location "M"
    worksheet.getCell("E10").value = textData[14] + " - " + textData[13]; // Department / Area "O and N"
    worksheet.getCell("E9").value = textData[15]; // Address
    worksheet.getCell("E11").value = textData[16]; // City
    worksheet.getCell("H11").value = textData[17]; // State
    worksheet.getCell("I11").value = textData[18]; // Zip Code
    worksheet.getCell("E13").value = textData[19]; // Contact
    worksheet.getCell("E14").value = textData[20]; // Telephone

    //STAIRS
    if (textData[22] == "Yes") {
      worksheet.getCell("M8").value = `STAIRS: YES\n`;
    }

    // IP Address
    if (textData[23] != "") {
      worksheet.getCell("M8").value =
        worksheet.getCell("M8").value +
        `IP ADDRESS DETAILS\n${textData[23]}\n${textData[24]}\n${textData[25]}\n`; // Special Instructions
    }

    //MAC Address
    if (textData[26] != "") {
      worksheet.getCell("M8").value =
        worksheet.getCell("M8").value + `MAC ADDRESS: ${textData[26]}\n`;
    }

    // Accessories:
    const accInfo = [];

    for (let i = 4; i < 10; i++) {
      if (textData[i] != "") {
        const getItem = await this.getPartNumbers(textData[i]);
        if (getItem) {
          accInfo.push(getItem);
        }
      }
    }

    accInfo.map((item, idx) => {
      let row = 16 + idx + 1;
      worksheet.getCell(`E${row}`).value = item.product;
      worksheet.getCell(`G${row}`).value = item.description;
    });

    // Name File
    let locationName = `${textData[12].substring(0, 15)}`;
    locationName = locationName.split(/[\s]+/).join("-"); //| Replace Spaces with dashes

    const newFile = workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      return {
        name: `${locationName}-${textData[3]}-${count}.xlsx`,
        input: blob,
      };
    });

    return newFile;
  },
  async getWorkbook(workbookFile) {
    const tempfilereader = new FileReader();
    tempfilereader.readAsArrayBuffer(workbookFile);

    return new Promise((resolve) => {
      tempfilereader.onload = async () => {
        const buffer = tempfilereader.result;
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(buffer);
        resolve(workbook);
      };
    });
  },
  clearWorksheet(worksheet) {
    worksheet.getCell("S2").value = ""; // Order #
    worksheet.getCell("B16").value = ""; // Product Code
    worksheet.getCell("E16").value = ""; // Model
    worksheet.getCell("G16").value = ""; // Desc
    worksheet.getCell("J16").value = ""; // Serial
    worksheet.getCell("M16").value = ""; // Equipment ID
    worksheet.getCell("S6").value = ""; // Delivery Date

    worksheet.getCell("E8").value = ""; // Location

    worksheet.getCell("E9").value = ""; // Address

    worksheet.getCell("E10").value = ""; // Department / Area

    worksheet.getCell("E11").value = ""; // City

    worksheet.getCell("H11").value = ""; // State
    worksheet.getCell("I11").value = ""; // Zip
    worksheet.getCell("E13").value = ""; // Contact
    worksheet.getCell("E14").value = ""; // Telephone

    worksheet.getCell("M8").value = ""; // Special Instructions

    // Accessories:
    const rows = [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];
    rows.map((item) => {
      worksheet.getCell(`B${item}`).value = "";
      worksheet.getCell(`C${item}`).value = "";
      worksheet.getCell(`E${item}`).value = "";
      worksheet.getCell(`G${item}`).value = "";
      worksheet.getCell(`J${item}`).value = "";
      worksheet.getCell(`M${item}`).value = "";
      worksheet.getCell(`N${item}`).value = "";
      worksheet.getCell(`P${item}`).value = "";
      worksheet.getCell(`R${item}`).value = "";
    });

    return worksheet;
  },
  async getPartNumbers(material) {
    const mat = material.trim();

    const materials = {
      MX3071: { product: "MX3071", description: "30ppm Color MFD" },
      MX3571: { product: "MX3571", description: "35ppm Color MFD" },
      MX4071: { product: "MX4071", description: "40ppm Color MFD" },
      MX5071: { product: "MX5071", description: "50ppm Color MFD" },
      MX7081: {
        product: "MX7081",
        description: "70ppm Color/75 ppm Mono MFD",
      },
      MXM3071: { product: "MXM3071", description: "30ppm Monochrome MFD" },
      MXC407P: { product: "MXC407P", description: "40ppm Color Printer" },
      MXC407F: { product: "MXC407F", description: "40ppm Color A4 MFD" },
      MXB376W: { product: "MXB376W", description: "37ppm Monochrome MFD" },
      MXB467P: { product: "MXB467P", description: "46ppm Monochrome Printer" },
      MXCS14: { product: "MXCS14", description: "Paper Tray" },
      MXCS17: { product: "MXCS17", description: "Paper Tray" },
      "MXCS17 (2)": { product: "MXCS17", description: "Qty. 2 - Paper Tray" },
      "MXCS17 (3)": { product: "MXCS17", description: "Qty. 3 - Paper Tray" },
      MXCS18: { product: "MXCS18", description: "Paper Tray" },
      MXCS20: { product: "MXCS20", description: "Paper Tray" },
      MXCS28: { product: "MXCS28", description: "Paper Tray" },
      "MXCS28 (2)": { product: "MXCS28", description: "Qty. 2 - Paper Tray" },
      MXDE27: { product: "MXDE27N", description: "Paper Tray" },
      MXDE27N: { product: "MXDE27N", description: "Paper Tray" },
      MXFN27: { product: "MXFN27N", description: "Internal Finisher" },
      MXFN27N: { product: "MXFN27N", description: "Internal Finisher" },
      MXFN28: { product: "MXFN28", description: "Finisher" },
      MXFN30: { product: "MXFN30", description: "Finisher" },
      MXFN35: { product: "MXFN35", description: "Finisher" },
      MXRB12N: { product: "MXRB12N", description: "Paper Pass" },
      MXRB25: { product: "MXRB25", description: "Paper Pass" },
      "40C2300 Stand": { product: "40C2300", description: "Stand" },
      "35S8502": { product: "35S8502", description: "Stand" },
      MXPN14B: { product: "MXPN14B", description: "Punch Kit" },
      MXPN15B: { product: "MXPN15B", description: "Punch Kit" },
      MXPN16B: { product: "MXPN16B", description: "Punch Kit" },
      "27X0400": { product: "27X0400", description: "Hard Drive Kit" },
      ACDFB4BELPSHP: { product: "ACDFB4BELPSHP", description: "Card Reader" },
      ACDFB4BELPSHP6: { product: "ACDFB4BELPSHP6", description: "Card Reader" },
    };

    return materials[mat];
  },
};
